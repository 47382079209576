import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header'
import Link from '../components/Link'
import { colorArray } from '../assets/lib/constants'

const MemberDetailPageContent = ({ data, index }) => {
  const member = data.memberDataJson

  const MemberTags = () => {
    if (member.tags) {
      return (
        <div className="member-info__tags-container">
          {member.tags.map((tag) => {
            return <div className="member-info__tag" key={tag}>{ tag }</div>
          })}
        </div>
      )
    }
    return null
  }

  const MemberWebsites = () => {
    if (member.urls) {
      if (member.urls.website) {
        return (
          <a className="member-info__link" href={ member.urls.website } target="_blank" rel="noreferrer">ウェブサイト</a>
        )
      }
      if (member.urls.blog) {
        return (
          <a className="member-info__link" href={ member.urls.blog } target="_blank" rel="noreferrer">ブログ</a>
        )
      }
    }
    return null
  }
  
  const MemberSnsUrls = () => {
    if (member.snsUrls) {
      return (
        <ul className="member-info__sns-container">
          {Object.entries(member.snsUrls).map(([sns, url]) => {
            if (url) {
              return (
                <li className="member-info__sns-link" key={sns}>
                  <Link href={ url } ><img className="member-info__sns-image" src={`/image/common/sns/ico_logo_${ sns }.svg`} alt={sns} /></Link>
                </li>
              )
            }
            return null
          })}
        </ul>
      )
    }
    return null
  }

  const MemberUrls = () => {
    if (member.urls || member.snsUrls) {
      if (Object.values(member.snsUrls).filter((url) => url).length > 0) {
        return (
          <div className="member-info__url-container">
            <MemberWebsites />
            <MemberSnsUrls />
          </div>
        )
      }
    }
    return null
  }
  
  return (
    <div className="main">
      <section className="member-detail">
        <div id="particles-js" className="hero__background hero__background--member-detail"></div>
        <div className="member-detail__upper">
          <div className="member-info">
            <div className="member-info__container">
              <div className="member-info__name">{ member.name }</div>
              <div className="member-info__title">{ member.title }</div>
              <MemberTags />
              <MemberUrls />
            </div>
          </div>
          <div className="member-detail__image-container">
            <div className="member">
              <div className={`member__image-frame member__image-frame--${colorArray[index % 4]}`}>
                <div className="member__image-container">
                  <img className="member__image member__image--original" src={member.image.childImageSharp.fluid.src}  alt="member" />
                </div>
              </div>
            </div>
          </div>
          <div className="member-location">
            <div className="member-location__container">
              <svg className={`member-location__map-point member-location__map-point--location-${ member.location }`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 48">
                <defs>
                  <linearGradient id="combined-shape-copy-2-a" x1="0%" x2="100%" y1="50%" y2="50%">
                    <stop offset="0%" className={`member-location__map-point--stop1-${ colorArray[index % 4]}`}/>
                    <stop offset="100%" className={`member-location__map-point--stop2-${ colorArray[index % 4]}`}/>
                  </linearGradient>
                </defs>
                <path fill="url(#combined-shape-copy-2-a)" fillRule="evenodd" d="M1209.90779,351.530576 C1209.90779,343.506085 1203.21216,337 1194.9539,337 C1186.69563,337 1180,343.506085 1180,351.530576 C1180,351.939147 1180.02582,352.347719 1180.06393,352.757485 C1180.08483,352.991637 1180.11434,353.221011 1180.14631,353.450385 C1180.16475,353.578212 1180.18442,353.70604 1180.20655,353.833868 C1180.28032,354.284253 1180.37498,354.72508 1180.49055,355.159934 C1180.54588,355.380945 1180.6012,355.601956 1180.66514,355.822967 C1180.67128,355.840887 1180.6762,355.858807 1180.68235,355.876727 C1183.49166,365.374225 1194.10926,375.191891 1195.00061,385 C1196.02844,373.687822 1210,362.362503 1210,351.530576 L1209.90779,351.530576 Z M1195,359 C1198.31371,359 1201,356.313708 1201,353 C1201,349.686292 1198.31371,347 1195,347 C1191.68629,347 1189,349.686292 1189,353 C1189,356.313708 1191.68629,359 1195,359 Z" transform="translate(-1180 -337)"/>
              </svg>
              <img className="member-location__map" src="/image/common/map-world.svg" alt="map-world" />
            </div>
          </div>
        </div>
  
        <div className="member-profile">
          <p className="member-profile__body">{ member.profile }</p>
        </div>
  
        <div className="back-to-member-list">
          <Link
            className={`back-to-member-list__button btn-${ colorArray[index % 4]}`}
            to={'/members/'}
          >
            メンバー一覧に戻る
          </Link>
        </div>
      </section>
    </div>
  )
}

const MemberDetailPageTemplate = ({ data, pageContext }) => (
  <Layout>
    <Header />
    <MemberDetailPageContent
      data={data}
      index={pageContext.pageIndex}
    />
  </Layout>
)

export const query = graphql`
  query MemberData($pagePath: String) {
    memberDataJson(path: {eq: $pagePath}) {
      location
      name
      path
      profile
      snsUrls {
        facebook
        github
        instagram
        linkedin
        twitter
      }
      tags
      title
      urls {
        website
        blog
      }
      image {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  }
`

export default MemberDetailPageTemplate
